@tailwind base;
@tailwind components;
@tailwind utilities;

textarea:focus,
input:focus {
  outline: none;
}

@font-face {
  font-family: 'myriad pro';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.cdnfonts.com/s/492/MYRIADPRO-REGULAR.woff) format('woff')
}

@font-face {
  font-family: 'myriad pro';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.cdnfonts.com/s/492/MYRIADPRO-COND.woff) format('woff')
}

@font-face {
  font-family: 'myriad pro';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.cdnfonts.com/s/492/MYRIADPRO-CONDIT.woff) format('woff')
}

@font-face {
  font-family: 'myriad pro';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.cdnfonts.com/s/492/MyriadPro-Light.woff) format('woff')
}

@font-face {
  font-family: 'myriad pro';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.cdnfonts.com/s/492/MYRIADPRO-SEMIBOLD.woff) format('woff')
}

@font-face {
  font-family: 'myriad pro';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.cdnfonts.com/s/492/MYRIADPRO-SEMIBOLDIT.woff) format('woff')
}

@font-face {
  font-family: 'myriad pro';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.cdnfonts.com/s/492/MYRIADPRO-BOLDCOND.woff) format('woff')
}

@font-face {
  font-family: 'myriad pro';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.cdnfonts.com/s/492/MYRIADPRO-BOLD.woff) format('woff')
}

@font-face {
  font-family: 'myriad pro';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.cdnfonts.com/s/492/MYRIADPRO-BOLDIT.woff) format('woff')
}

@font-face {
  font-family: 'myriad pro';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.cdnfonts.com/s/492/MYRIADPRO-BOLDCONDIT.woff) format('woff')
}

@font-face {
  font-family: 'Ailerons';
  src: url('/src/assets/fonts/Ailerons.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Amertha';
  src: url('/src/assets/fonts/Amertha.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Antonio-Regular';
  src: url('/src/assets/fonts/Antonio-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: myriad pro, sans-serif;
}